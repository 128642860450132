.unpublish-stats {
  grid-column-start: 6;
  grid-column-end: 13;
  margin-top: 24px;
}

.unpublish-stats__blurb {
  padding: 16px;
  line-height: 1.5em;
}

.unpublish-stats__btn {
  background: var(--red-main) !important;
  color: var(--light-1) !important;
  margin-left: 16px;
  transition: all 0.2s ease-out;
}

.unpublish-stats__btn.loading {
  padding-left: 16px;
  padding-right: 16px;
}
