.upload-data {
  background: white;
  /* width: 80%; */
  border-radius: 3px;
  padding: 16px;
  box-sizing: border-box;
  max-width: 90%;
}

.upload-data .title {
  font-weight: 800;
  margin-bottom: 16px;
}

.upload-data .title.with-secondary {
  margin-bottom: 8px;
}

.upload-data .secondary-text {
  margin-bottom: 16px;
}

.upload-data .table-wrapper {
  height: 320px;
  overflow: auto;
}

.upload-data table {
  width: 100%;
  margin-bottom: 16px;
  /* display: block; */
}

.upload-data table tbody {
}

.upload-data table tr {
  text-align: left;
  border-bottom: solid 1px black;
  height: 32px;
}

.upload-data table tr th {
}

.upload-data table tr th:last-child {
  text-align: right;
}

.upload-data table tr td {
  cursor: pointer;
  min-width: 100px;
}

.upload-data table tr td input {
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #d9d9d9;
}

.upload-data table tr td:hover {
  background: whitesmoke;
}

.upload-data table tr td.empty {
  background: rgba(255, 0, 0, 0.1);
}

.upload-data table tr td:last-child {
  text-align: right;
}

.upload-data .footer {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
}

.upload-data .footer .App-btn {
  margin-left: 16px;
}

.upload-data .footer .App-btn.outline {
  border: solid 1px #37a1fa;
  color: #37a1fa;
  background: white;
}

.upload-data .template-link {
  color: #37a1fa;
}
