.editable-field {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* height: 36px; */
  -ms-align-items: center;
  align-items: center;
  /* margin-top: 16px; */
  margin-bottom: 24px;
  overflow: hidden;
  /* height: 54px; */
}

.editable-field:last-child {
  margin-bottom: 0;
}

.editable-field:hover {
  /* cursor: pointer; */
}

.editable-field:hover .value {
  font-weight: 600;
}

.editable-field .meta {
  flex-grow: 1;
  overflow: hidden;
}

.editable-field .label {
  color: grey;
  margin-bottom: 4px;
}

.editable-field .value {
  transition: all 0.2s ease-out;
}

.editable-field .options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
}

.editable-field .options .option {
  height: 24px;
  width: 24px;
  /* background: whitesmoke; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 3px;
  font-size: 12px;
  transition: all 0.2s ease-out;
}

.editable-field .options .option:hover {
  cursor: pointer;
}

.editable-field .options .option.outline {
  color: var(--blue);
  border: solid 1px var(--blue);
  box-sizing: border-box;
}

.editable-field .options .option.edit {
  background: var(--yellow-subtle);
  color: var(--yellow);
}

.editable-field .options .option.edit:hover {
  background: var(--yellow);
  color: white;
}

.editable-field .options .option.delete {
  color: var(--red);
}

.editable-field-form {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.editable-field-form.column {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.editable-field-form .title {
  flex: 1;
  padding-right: 16px;
}

.editable-field-form .form-group {
  flex: 1;
  position: relative;
  margin-right: 16px;
}

.editable-field-form.column .form-group {
  margin-right: 0;
  width: 100%;
  margin-bottom: 16px;
}

.editable-field-form .form-group:last-child {
  margin-right: 0;
}

.editable-field-form .form-group input {
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding-left: 8px;
  outline-color: var(--blue);
}

.editable-field-form .form-group select {
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  padding-left: 8px;
  outline-color: var(--blue);
}

.editable-field-form .label {
  color: grey;
  position: absolute;
  background: white;
  top: -6px;
  left: 6px;
  font-size: 10px;
  padding-left: 4px;
  padding-right: 4px;
}

.editable-field-form .options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.editable-field-form.column .options {
  width: 100%;
  justify-content: flex-end;
}

.editable-field-form .options .option {
  height: 36px;
  /* width: 24px; */
  /* background: whitesmoke; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 3px;
  font-size: 12px;
  transition: all 0.2s ease-out;
  font-weight: 600;
}

.editable-field-form .options .option:first-child {
  margin-left: 0;
}

.editable-field-form .options .option:hover {
  cursor: pointer;
}

.editable-field-form .options .option.submit {
  background: var(--green);
  outline: none;
  border: none;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
}

.editable-field-form .options .option.cancel {
  height: 36px;
  width: 36px;
  background: whitesmoke;
  color: grey;
}

.editable-field-form .options .option.delete {
  background: var(--red);
  outline: none;
  border: none;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
}

.editable-field-form .options .option.cancel:hover {
  background: var(--red);
  color: white;
}
