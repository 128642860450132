.profile-claims-list-page {
  display: flex;
  flex-direction: row;
  padding: 24px;
}

.profile-claim-item {
  background: white;
  /* padding: 16px; */
  border-radius: 8px;
  margin-right: 24px;
}

.profile-claim-item__id {
  font-weight: 700;
  padding: 16px;
}

.profile-claim-item__user {
  padding: 16px;
  padding-top: 0;
}

.profile-claim-item__options {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px whitesmoke;
  /* margin-top: 16px; */
}

.profile-claim-item__options__option {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  height: 32px;
}

.profile-claim-item__options__option:hover {
  background: whitesmoke;
}

.profile-claim-item__options__option.approve {
  color: #60bb60;
}

.profile-claim-item__options__option.dismiss {
  color: grey;
  border-left: solid 1px whitesmoke;
}

.profile-claim-item__confirmation {
  border-top: solid 1px whitesmoke;
  padding: 16px;
}

.profile-claim-item__confirmation__options {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.profile-claim-item__confirmation__options__option {
  height: 24px;
  /* width: 50%; */
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-claim-item__confirmation__options__option.confirm {
  background: #60bb60;
  color: white;
  border-radius: 4px;
  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
}

.profile-claim-item__confirmation__options__option.cancel {
  color: grey;
}
