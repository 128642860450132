.App-layout {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 100%;
  background: var(--bg);
}

.App-layout__menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--dark-1);
  border-right: solid 1px var(--dark-2);
  /* box-shadow: var(--elevation-3); */
  z-index: 10;
}

.App-layout__menu__brand {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 16px;
  font-style: italic;
  font-size: 6px;
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  /* border-bottom: solid 1px var(--light-2); */
  font-family: var(--nhg-display);
  font-weight: 700;
}

.App-layout__menu__brand img {
  height: 24px;
}

.App-layout__menu__brand__logo__suffix {
  margin-left: -18px;
  color: var(--light-1);
  letter-spacing: 1px;
  /* display: inline-block; */
  /* margin-top: -2px; */
  /* margin-bottom: 12px; */
}

.App-layout__menu__items {
  flex-grow: 1;
}

.App-layout__menu__items__item-wrapper {
  padding-right: 8px;
}

.App-layout__menu__items__item-wrapper__item {
  display: block;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 12px; */
  padding-left: 16px;
  /* font-family: var(--nhg-display); */
  font-size: 10px;
  /* letter-spacing: 2px; */
  /* font-weight: 100; */
  color: var(--dark-4);
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.2s ease-out;
  border-left: solid 4px transparent;
}

.App-layout__menu__items__item-wrapper__item.selected {
  background: var(--dark-2);
  color: var(--light-4);
  font-weight: 700;
  border-left: solid 4px var(--blue-main);
}

.App-layout__menu__footer {
  border-top: solid 1px var(--dark-2);
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  font-size: 10px;
}

.App-layout__menu__footer__icon {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.App-layout__menu__footer__text {
  padding-left: 12px;
}
.App-layout__menu__footer__text__primary {
  font-weight: 700;
  color: var(--light-4);
  /* margin-bottom: 2px; */
}
.App-layout__menu__footer__text__secondary {
  color: var(--dark-4);
}
.App-layout__menu__footer__options {
  margin-left: auto;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.App-layout__menu__footer__options__option {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-layout__menu__footer__options__icon {
  fill: var(--dark-4);
  transform: rotate(90deg);
  height: 16px;
}

.App-layout__menu__footer__options__account__menu__item {
  height: 32px;
  /* padding: 12px; */
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-layout__content {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App-layout__modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 15;
  transition: all 0.2s ease-out;
  /* pointer-events: none; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* overflow-x: hidden;
    overflow-y: auto; */
}

.App-layout__modal.open {
  opacity: 0;
  pointer-events: all;
}
