.sport-positions-page {
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 20px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 40px;
}
