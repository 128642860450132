.upload-index-item {
  /* background: white; */
  margin-bottom: 24px;
  /* padding: 16px; */
}

.upload-index-item__header {
  height: 36px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 8px;
  font-weight: 700;
  font-family: var(--nhg-display);
  letter-spacing: 1.4px;
  /* border-bottom: solid 1px var(--light-2); */
  color: var(--dark-3);
}

.upload-index-item__header__timestamp {
  margin-left: auto;
}

.upload-index-item__body {
}

.upload-index-item__body__text-group {
  padding: 16px;
}

.upload-index-item__body__text-group__label {
  color: var(--dark-4);
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 400;
}

.upload-index-item__body__text-group__value {
  font-weight: 600;
  color: var(--dark-1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upload-index-item__body__text-group__value__img {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  overflow: hidden;
}

.upload-index-item__body__text-group__value__text {
  padding-left: 12px;
  font-size: 16px;
}

.upload-index-item__body__text-group__value__link {
  margin-left: 8px;
  color: var(--blue-main);
  cursor: pointer;
}
