.dashboard-report {
  margin-top: 24px;
}

.dashboard-report__body {
  padding: 16px;
}

.dashboard-report__body__blurb {
}

.dashboard-report__body__blurb .bold {
  font-weight: 700;
}

.dashboard-report__body__link {
  cursor: pointer;
  margin-top: 16px;
  color: var(--blue-main);
  text-decoration: underline;
  /* font-weight: 600; */
}
