.event-fixtures-list {
  grid-column-start: 6;
  grid-column-end: 13;
  margin-top: 24px;
}

.event-fixtures-list__stages {
  /* display: flex;
  flex-direction: row;
  align-items: center; */
}

.event-fixtures-list__stages__header {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
}

.event-fixtures-list__stages__header__title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--dark-2);
  font-size: 10px;
}

.event-fixtures-list__stages__header__navigate-btn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.event-fixtures-list__stages__header__navigate-btn__icon {
  stroke: var(--light-4);
  height: 14px;
}

.event-fixtures-list__stages__header__navigate-btn.next
  .event-fixtures-list__stages__header__navigate-btn__icon {
  transform: rotateZ(180deg);
}

.event-fixtures-list__stages__body {
}

.event-fixtures-list__stages__stage {
}

.event-fixtures-list__stages__stage__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
}

.event-fixtures-list__stages__stage__empty__options {
}

.event-fixtures-list__stages__stage__empty__options__option {
}

.event-fixtures-list__stages__stage__list {
}

.event-fixtures-list__stages__stage__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.event-fixtures-list__stages__stage__list__item:hover {
  background: var(--light-2);
}

.event-fixtures-list__stages__stage__list__item__team {
  width: 40%;
  display: flex;
  flex-direction: row;
}

.event-fixtures-list__stages__stage__list__item__team.away {
  flex-direction: row-reverse;
}

.event-fixtures-list__stages__stage__list__item__team__img {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.event-fixtures-list__stages__stage__list__item__team__text {
  padding-left: 12px;
  padding-right: 12px;
}

.event-fixtures-list__stages__stage__list__item__team__text__primary {
  font-weight: 700;
  color: var(--dark-1);
}

.event-fixtures-list__stages__stage__list__item__team__text__secondary {
  color: var(--dark-4);
}

.event-fixtures-list__stages__stage__list__item__seperator {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
