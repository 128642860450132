.App-button {
  min-height: 28px;
  border: none;
  outline: none;
  border-radius: 4px;
  /* background: white; */
  padding-left: 12px;
  padding-right: 12px;
  font-family: var(--nhg-display);
  font-size: 8px;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 2px;
  transition: all 0.2s ease-out;
  color: var(--dark-3);
}
