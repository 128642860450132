.dashboard-page {
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.dashboard-page__left-column {
  grid-column-start: 1;
  grid-column-end: 7;
}

.dashboard-page__right-column {
  grid-column-start: 7;
  grid-column-end: 13;
}
