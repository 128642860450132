.access-control-page__admins {
  grid-column-start: 1;
  grid-column-end: 6;
  margin-top: 24px;
}

.access-control-page__admins__items__item {
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
}

.access-control-page__admins__items__item__img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  overflow: hidden;
}

.access-control-page__admins__items__item__text {
  padding-left: 12px;
}

.access-control-page__admins__items__item__text__primary {
  font-weight: 700;
}

.access-control-page__admins__items__item__text__secondary {
  color: var(--dark-4);
}

.access-control-page__admins__items__item__revoke {
  margin-left: auto;
  background: var(--red-subtle);
  color: var(--red-darker);
  /* border: solid 1px var(--red-darker); */
  height: 24px;
  min-height: 24px;
}

.access-control-page__admins__items__item__revoke.disabled {
  background: var(--light-2);
  color: var(--light-4);
  border: none;
}

.access-control-page__admins__add-btn {
  color: var(--green-darker);
  cursor: pointer;
}
