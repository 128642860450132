.tree-node {
  /* margin-top: 16px; */
}

.tree-node .node-identifier {
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* margin-bottom: 8px; */
  height: 32px;
  transition: all 0.2s ease-out;
  /* padding-left: 8px; */
  padding-right: 8px;
  border-radius: 4px;
}

.tree-node .node-identifier .hover-wrapper {
  flex-grow: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  height: 100%;
  padding-left: 8px;
}

.tree-node .node-identifier:hover .node-options {
  border: solid 1px whitesmoke;
}

.tree-node .node-identifier:hover .hover-wrapper {
  background: whitesmoke;
}

.tree-node .node-identifier .node-label {
  flex-grow: 1;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.tree-node .node-identifier .node-label:hover {
  cursor: pointer;
}

.tree-node.add .node-identifier {
  color: var(--green);
}

.tree-node.add .node-identifier:hover {
  cursor: pointer;
  text-decoration: underline;
  background: none;
}

.tree-node .node-identifier .collapse-btn {
  /* margin-right: 4px; */
  width: 16px;
  height: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;

  color: grey;
}

.tree-node .node-identifier .collapse-btn:hover {
  cursor: pointer;
}

.tree-node .node-identifier .collapse-btn.expanded .icon {
  transform: rotateZ(90deg);
}

.tree-node .node-identifier .node-options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  -ms-align-items: center;
  align-items: center;
  padding-right: 8px;
  box-sizing: border-box;
  border: solid 1px transparent;
}

.tree-node .node-identifier .node-options .option {
  height: 20px;
  width: 20px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: white;
  color: grey;
  border-radius: 4px;
  margin-left: 12px;
  border: solid 1px var(--blue);
  color: var(--blue);
  box-sizing: border-box;
}

.tree-node .node-identifier .node-options .option.edit {
  background: var(--yellow-subtle);
  color: var(--yellow);
  border: none;
}

.tree-node .node-identifier .node-options .option.delete {
  background: var(--red-subtle);
  color: var(--red);
  border: none;
}

.tree-node .node-identifier .node-options .option:hover {
  background: var(--blue);
  color: white;
  cursor: pointer;
}

.tree-node .node-identifier .node-options .option.edit:hover {
  background: var(--yellow);
  color: white;
}

.tree-node .node-identifier .node-options .option.delete:hover {
  background: var(--red);
  color: white;
}

.tree-node .node-identifier .node-options .option.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.tree-node .node-body {
  box-sizing: border-box;
  padding-left: 16px;
  /* padding-top: 8px; */
}

.fixture-item {
  /* margin-top: 8px; */
  /* padding: 8px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  height: 56px;
}

.fixture-item .label {
  color: grey;
}

.fixture-item .meta {
  flex-grow: 1;
  /* padding: 8px; */
  /* padding-top: 4px; */
  /* padding-bottom: 4px; */
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
}

.fixture-item .options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  -ms-align-items: center;
  align-items: center;
  padding-right: 16px;
  box-sizing: border-box;
  border: solid 1px transparent;
}

.fixture-item .options .option {
  height: 20px;
  width: 20px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: white;
  color: grey;
  border-radius: 4px;
  margin-left: 16px;
  border: solid 1px var(--blue);
  color: var(--blue);
  box-sizing: border-box;
}

.fixture-item .options .option:hover {
  cursor: pointer;
}

.fixture-item .options .option.edit {
  background: var(--yellow-subtle);
  color: var(--yellow);
  border: none;
}

.fixture-item .options .option.delete {
  background: var(--red-subtle);
  color: var(--red);
  border: none;
}

.fixture-item:hover .meta {
  background: whitesmoke;
}

.fixture-item:hover .options {
  border: solid 1px whitesmoke;
}

.fixture-item:hover .options .option:hover {
  background: var(--blue);
  color: white;
  cursor: pointer;
}

.fixture-item:hover .options .option.edit:hover {
  background: var(--yellow);
  color: white;
}

.fixture-item:hover .options .option.delete:hover {
  background: var(--red);
  color: white;
}

.fixture-item:hover .options .option.disabled {
  pointer-events: none;
  opacity: 0.4;
}
