.mini-profile {
  width: 80%;
}

.mini-profile__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.mini-profile__header__img {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 4px;
}

.mini-profile__header__text {
  padding-left: 16px;
}

.mini-profile__header__text__primary {
  font-size: 20px;
  color: var(--dark-2);
  font-weight: 700;
  font-family: var(--nhg-display);
}

.mini-profile__header__text__secondary {
  color: var(--dark-3);
}

.mini-profile__body {
  padding: 16px;
}

.mini-profile__body__group {
}

.mini-profile__body__group__label {
  font-size: 8px;
  font-weight: 100;
  font-family: var(--nhg-display);
  letter-spacing: 1px;
  color: var(--dark-4);
}

.mini-profile__body__group__items {
}

.mini-profile__body__group__items__item {
  margin-top: 12px;
}

.mini-profile__body__group__items__item__primary {
  font-weight: 600;
}

.mini-profile__body__group__items__item__secondary {
  color: var(--dark-4);
}

.mini-profile__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-top: solid 1px var(--light-2);
  color: var(--blue-main);
  font-weight: 600;
  cursor: pointer;
}

.report-browser__meta__claims__claim {
  padding: 16px;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px var(--light-2);
}

.report-browser__meta__claims__claim__img {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background: var(--blue-subtle);
}

.report-browser__meta__claims__claim__text {
  padding-left: 12px;
  flex-grow: 1;
}

.report-browser__meta__claims__claim__text__primary {
  font-weight: 700;
  color: var(--dark-1);
}

.report-browser__meta__claims__claim__text__secondary {
  color: var(--dark-3);
  font-size: 10px;
  margin-top: 2px;
}

.report-browser__meta__claims__claim__text__link {
  color: var(--blue-darker);
  font-weight: 600;
  font-size: 10px;
  cursor: pointer;
  margin-top: 2px;
}

.report-browser__meta__claims__claim__options {
}

.report-browser__meta__claims__claim__options__option {
  margin-left: 12px;
}

.report-browser__meta__claims__claim__options__option.success {
  background: var(--green-darker) !important;
  color: var(--light-1) !important;
}
