.stat-type-relationships-editor {
  border: solid 1px whitesmoke;
  padding: 16px;
  margin-bottom: 16px;
}

.stat-type-relationships-editor .title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-right: 8px;
}

.stat-type-relationships-editor .title .close-editor {
  margin-left: auto;
  color: grey;
}

.stat-type-relationships-editor .relationship-section {
  margin-bottom: 16px;
}

.stat-type-relationships-editor .relationship-section:last-child {
  margin-bottom: 0;
}

.stat-type-relationships-editor .relationship-section .primary-text {
  font-weight: 600;
  margin-bottom: 4px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-right: 8px;
}

.stat-type-relationships-editor .relationship-section .primary-text .info-icon {
  margin-left: auto;
  color: grey;
}

.stat-type-relationships-editor .relationship-section .secondary-text {
}

.stat-type-relationships-editor .relationship-section .secondary-text.hide {
  display: none;
}

.stat-type-relationships-editor .relationship-section .list {
  margin-bottom: 8px;
  margin-top: 8px;
}

.stat-type-relationships-editor .relationship-section .list .list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  height: 32px;
  border-bottom: solid 1px whitesmoke;
  padding-left: 8px;
  padding-right: 8px;
}

.stat-type-relationships-editor
  .relationship-section
  .list
  .list-item:last-child {
  border-bottom: none;
}

.stat-type-relationships-editor
  .relationship-section
  .list
  .list-item
  .delete-relationship {
  margin-left: auto;
  color: grey;
}

.stat-type-relationships-editor
  .relationship-section
  .list
  .list-item
  .delete-relationship:hover {
  cursor: pointer;
  color: var(--red);
}

.stat-type-relationships-editor .relationship-section .list .list-item:hover {
  background: whitesmoke;
}

.stat-type-relationships-editor .relationship-section .footer-options {
  margin-top: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
