.enquiries-summary {
  margin-top: 24px;
}

.enquiries-summary__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
}

.enquiries-summary__tabs__tab {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg);
  font-weight: 400;
  margin-left: 16px;
  letter-spacing: 0.1px;
}

.enquiries-summary__tabs__tab.selected {
  border-bottom: solid 2px var(--blue-main);
  font-weight: 700;
}

.enquiries-summary__list {
}

.enquiries-summary__list__empty {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  /* font-weight: 600; */
  color: var(--dark-4);
}

.enquiries-summary__list__item {
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: hidden;
  border-bottom: solid 1px var(--light-2);
}

.enquiries-summary__list__item__content {
}

.enquiries-summary__list__item__content__subject {
  font-weight: 700;
  margin-bottom: 4px;
}

.enquiries-summary__list__item__content__description {
  line-height: 1.5em;
  color: var(--dark-3);
  /* width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 4px;
}

.enquiries-summary__list__item__content__timestamp {
  font-size: 8px;
  color: var(--dark-3);
}

.enquiries-summary__list__item__content__author {
  margin-bottom: 4px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.enquiries-summary__list__item__content__author__image {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: var(--blue-subtle);
  margin-right: 8px;
}

.enquiries-summary__list__item__content__author__name {
  color: var(--dark-3);
}
