.App-card__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 32px;
  font-size: 8px;
  font-weight: 700;
  font-family: var(--nhg-display);
  letter-spacing: 1.4px;
  border-bottom: solid 1px var(--light-2);
  color: var(--dark-3);
}

.App-card__title__options {
  margin-left: auto;
}
