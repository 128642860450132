.Table {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.Table__main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Table__main__header {
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Table__main__header__title {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--nhg-display);
}

.Table__main__header__options {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Table__main__header__options__option {
  height: 24px;
  min-height: auto;
  margin-left: 16px;
}

.Table__main__header__options__option.add {
  background: var(--green-darker);
  color: var(--light-2);
}

.Table__main__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Table__main__body__headers {
  height: 28px;
  /* background: var(--light-2); */
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  flex-shrink: 0;
  border-bottom: solid 1px var(--light-2);
  box-shadow: var(--elevation-1);
  /* border-top: solid 1px var(--light-3); */
  color: var(--dark-2);
  /* padding-left: 24px; */
}

.Table__main__body__headers__th {
  flex-shrink: 0;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Table__main__body__rows {
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 80px;
}

.Table__main__body__rows__row {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Table__main__body__rows__row.odd {
  background: var(--light-2);
}

.Table__main__body__rows__row.hover-pointer {
  cursor: pointer;
}

.Table__main__body__rows__row__td {
  flex-shrink: 0;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-3);
}

.Table__main__body__rows__row__td__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  overflow: hidden;
  background: var(--blue-subtle);
  margin-right: 12px;
}

.Table__filters {
  flex-shrink: 0;
}

/* .Table__main__body__rows__row__td__btn {
  background: var(--orange-subtle);
}

.Table__main__body__rows__row__td__btn__icon {
  height: 12px;
  fill: var(--orange-darker);
}

.Table__main__body__rows__row__td__btn:hover{

} */
