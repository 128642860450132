.App-data-uploader {
  height: 90%;
  width: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.App-data-uploader__meta {
  width: 25%;
  padding: 24px;
  border-right: solid 1px var(--light-3);
  display: flex;
  flex-direction: column;
  background: var(--dark-1);
  color: var(--light-1);
}

.App-data-uploader__meta__subtitle {
  font-family: var(--nhg-display);
  font-weight: 600;
  /* font-size: 24px; */
  /* line-height: 34px; */
  color: var(--dark-4);
  margin-bottom: 16px;
}

.App-data-uploader__meta__title {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.App-data-uploader__meta__blurb {
  margin-top: auto;
  color: var(--light-4);
  margin-bottom: 16px;
  line-height: 1.5em;
}

/* .App-data-uploader__meta__blurb__download-template {
  cursor: pointer;
  color: var(--blue-main);
} */

.App-data-uploader__meta__download-template-btn {
  background: none !important;
  border: solid 1px var(--blue-main);
  color: var(--blue-main) !important;
  width: 160px;
}

.App-data-uploader__content {
  width: 75%;
  height: 100%;
  overflow: hidden;
}

.App-data-uploader__content__input-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: dashed 2px var(--light-4);
  border-radius: 8px;
}

.App-data-uploader__content__input-wrapper__icon {
  height: 64px;
}

.App-data-uploader__content__input-wrapper__placeholder {
  margin-bottom: 12px;
  font-size: 16px;
  text-align: center;
}

.App-data-uploader__content__input-wrapper__seperator {
  color: var(--dark-4);
  font-size: 10px;
  margin-bottom: 12px;
}

.App-data-uploader__content__input-wrapper__btn {
  background: var(--blue-darker);
  color: var(--light-2);
}

.App-data-uploader__content__input-wrapper input {
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.App-data-uploader__content__input-wrapper input:focus {
  outline: none;
}

.App-data-uploader__content__datasheet {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.App-data-uploader__content__datasheet__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.App-data-uploader__content__datasheet__header__text {
  font-size: 10px;
}

.App-data-uploader__content__datasheet__header__text__secondary {
}

.App-data-uploader__content__datasheet__header__text__primary {
  font-weight: 600;
  color: var(--dark-1);
}

.App-data-uploader__content__datasheet__header__dismiss {
  margin-left: auto;
  font-weight: 600;
  color: var(--blue-darker);
  cursor: pointer;
}

.App-data-uploader__content__datasheet__header__dismiss__icon {
  height: 14px;
  stroke: var(--light-4);
}

.App-data-uploader__content__datasheet__footer {
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}

.App-data-uploader__content__datasheet__footer__info {
  margin-right: 24px;
  font-size: 10px;
}

.App-data-uploader__content__datasheet__footer__submit-btn {
  background: var(--green-darker);
  color: var(--light-2);
}

.App-data-uploader__content__datasheet__footer__submit-btn.disabled {
  background: var(--light-2);
  color: var(--dark-4) !important;
  pointer-events: none;
}

/* TABLE */

.App-data-uploader__content__datasheet__table {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.App-data-uploader__content__datasheet__table__head {
  position: relative;
  height: 24px;
}

.App-data-uploader__content__datasheet__table__body {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

.App-data-uploader__content__datasheet__table__body__columns {
  display: flex;
  flex-direction: row;
  will-change: transform;
}

.App-data-uploader__content__datasheet__table__body__columns.fixed {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  z-index: 1;
}

.App-data-uploader__content__datasheet__table__body__columns.flexible {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.App-data-uploader__content__datasheet__table__body__columns__column {
  flex-shrink: 0;
}

.App-data-uploader__content__datasheet__table__th {
  height: 20px;
  background: var(--light-2);
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  flex-shrink: 0;
  font-weight: 600;
  font-family: var(--nhg-display);
}

.App-data-uploader__content__datasheet__table__td {
  height: 32px;
  /* background: var(--light-2); */
  /* font-size: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-left: 16px; */
  overflow: hidden;
  border-bottom: solid 1px var(--light-2);
  border-right: solid 1px var(--light-2);
  flex-shrink: 0;
}

.App-data-uploader__content__datasheet__table__td__form {
  height: 100%;
  width: 100%;
}

.App-data-uploader__content__datasheet__table__td__form__input {
  height: 100%;
  width: 100%;
  padding-left: 16px;
  outline-width: 1px;
  outline-color: var(--blue-darker);
}

/* .app-uploader .input-container input {
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.app-uploader .input-container input:focus {
  outline: none;
} */
