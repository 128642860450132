.org-info-page__members {
  grid-column-start: 6;
  grid-column-end: 13;
  margin-top: 24px;
}

.org-info-page__members__add-btn {
  color: var(--green-darker);
  cursor: pointer;
}

.org-info-page__members__items {
  padding-top: 8px;
}

.org-info-page__members__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.org-info-page__members__items__item__img {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.org-info-page__members__items__item__text {
  padding-left: 12px;
}

.org-info-page__members__items__item__text__primary {
  font-weight: 700;
}

.org-info-page__members__items__item__text__secondary {
  color: var(--dark-4);
}

.org-info-page__members__items__item__options {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.org-info-page__members__items__item__options__option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 8px;
  border: none;
}

.org-info-page__members__items__item__options__option__icon {
  height: 14px;
}

.org-info-page__members__items__item__options__option.edit {
  background: var(--orange-subtle);
}

.org-info-page__members__items__item__options__option.edit
  .org-info-page__members__items__item__options__option__icon {
  fill: var(--orange-darker);
}

.org-info-page__members__items__item__options__option.delete {
  background: var(--red-subtle);
}

.org-info-page__members__items__item__options__option.delete
  .org-info-page__members__items__item__options__option__icon {
  stroke: var(--red-darker);
}
