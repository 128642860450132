.create-formation__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* height: 100%; */
}

.create-formation__form__label {
  padding-left: 16px;
  margin-bottom: 16px;
}

.create-formation__form__group {
  margin-top: auto;
}

.create-formation__form__submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 16px;
  padding-right: 16px;
}

.create-formation__form__submit__btn {
  display: inline-block;
  width: 160px;
  margin-top: 0;
}
