.App {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  --green: #60bb60;
  --yellow: #ffcc00;
  --red: #ff3b3b;
  --blue: #37a1fa;
  --yellow-lighter: #fded72;
  --yellow-subtle: #fffee5;
}

.App .App-header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 40px;
  border-bottom: solid 1px whitesmoke;
  flex-shrink: 0;
  -ms-align-items: center;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
}

.App .App-header .logo {
  margin-right: auto;
}

.App .App-header .logo img {
  height: 20px;
}

.App .App-body {
  flex-grow: 1;
  overflow: hidden;
}

.App .App-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  transition: all 0.2s ease-out;
  /* pointer-events: none; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* overflow-x: hidden;
    overflow-y: auto; */
}

.App .App-modal.open {
  opacity: 0;
  pointer-events: all;
}

.App .App-btn {
  color: white;
  border-radius: 3px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  /* margin-left: 16px; */
  font-weight: 600;
  background: var(--blue);
}

.App .App-btn.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.App .App-btn.success {
  background: var(--green);
}

.App .App-btn.warning {
  background: var(--yellow);
}

.App .App-btn.danger {
  background: var(--red);
}

.App .App-btn.outline {
  background: white;
  border: solid 1px var(--blue);
  color: var(--blue);
}

.App .App-btn:hover {
  cursor: pointer;
}

.App .loading-spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  display: inline;
  height: 32px;
  width: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: #3e7bfa;
  font-size: 24px;
}

.App .loading-wrapper {
  height: 250px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.App .hover-pointer {
  cursor: pointer;
}

.App .App-menu {
  background: white;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  width: 224px;
  padding-bottom: 32px;
  /* border-radius: 8px; */
  margin-left: auto;
  height: 100%;
  box-sizing: border-box;
}

.App .App-menu img {
  height: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.App .App-menu .link {
  height: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  color: black;
}

.App .App-menu .link:hover {
  color: var(--blue);
  cursor: pointer;
}
