.fixture-participant {
  margin-bottom: 16px;
}

.fixture-participant .title {
  margin-bottom: 4px;
}

.fixture-participant .team {
}

.fixture-participant .placeholder {
  color: grey;
}

.fixture-participant .edit-button {
  color: grey;
}
.fixture-participant .edit-button:hover {
  cursor: pointer;
  text-decoration: underline;
}
