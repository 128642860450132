.input-file {
  height: 100%;
  width: 100%;
  /* border: solid 1px whitesmoke; */
}

.input-file:hover {
  cursor: pointer;
}

.input-file input {
  display: none;
}

.input-file .selection-input {
  height: 100%;
  width: 100%;
  /* background: whitesmoke; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.input-file .selection-input .icon {
  font-size: 24px;
  margin-bottom: 8px;
  color: #545454;
}

.input-file .selection-input .text {
}

.input-file .selection-input img {
  /* height: 100%; */
  width: 100%;
}
