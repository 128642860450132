.Form-field-search-selected {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Form-field-search-selected__value {
  flex-grow: 1;
  padding-left: 8px;
}

.Form-field-search-selected__dismiss {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.Form-field-search-selected__dismiss__icon {
  stroke: var(--red-lighter);
  height: 14px;
}
