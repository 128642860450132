.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: white;
  color: #9880ff;
  animation: dotFlashing 0.6s infinite alternate;
  animation-delay: 0.25s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: white;
  color: #9880ff;
  animation: dotFlashing 0.6s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: white;
  color: #9880ff;
  animation: dotFlashing 0.6s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dotFlashing {
  0% {
    background-color: white;
  }
  50%,
  100% {
    background-color: #ffffff9d;
  }
}

@keyframes dotFlashingBlue {
  0% {
    background-color: var(--blue-lighter);
  }
  50%,
  100% {
    background-color: var(--light-2);
  }
}

@keyframes dotFlashingRed {
  0% {
    background-color: var(--red-lighter);
  }
  50%,
  100% {
    background-color: var(--light-2);
  }
}

@keyframes dotFlashingGrey {
  0% {
    background-color: var(--light-4);
  }
  50%,
  100% {
    background-color: var(--light-2);
  }
}

.dot-flashing.blue {
  animation: dotFlashingBlue 0.6s infinite alternate;
  animation-delay: 0.25s;
}

.dot-flashing.blue::before {
  animation: dotFlashingBlue 0.6s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing.blue::after {
  animation: dotFlashingBlue 0.6s infinite alternate;
  animation-delay: 0.5s;
}

.dot-flashing.red {
  animation: dotFlashingRed 0.6s infinite alternate;
  animation-delay: 0.25s;
}

.dot-flashing.red::before {
  animation: dotFlashingRed 0.6s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing.red::after {
  animation: dotFlashingRed 0.6s infinite alternate;
  animation-delay: 0.5s;
}

.dot-flashing.grey {
  animation: dotFlashingGrey 0.6s infinite alternate;
  animation-delay: 0.25s;
}

.dot-flashing.grey::before {
  animation: dotFlashingGrey 0.6s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing.grey::after {
  animation: dotFlashingGrey 0.6s infinite alternate;
  animation-delay: 0.5s;
}

.dot-flashing.small,
.dot-flashing.small::before,
.dot-flashing.small::after {
  width: 4px;
  height: 4px;
  border-radius: 2px;
}

.dot-flashing.small::before {
  left: -8px;
}

.dot-flashing.small::after {
  left: 8px;
}
