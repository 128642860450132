* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 12px;

  --bg: #edf2f4;
  --dark-1: #2e3438;
  --dark-2: #434c51;
  --dark-3: #5a666d;
  --dark-4: #818f97;

  --light-1: #ffffff;
  --light-2: #f6f8f9;
  --light-3: #dae2e7;
  --light-4: #bbcad3;

  --blue-main: #13a0fa;
  --blue-darkest: #046caf;
  --blue-darker: #058ee6;
  --blue-lighter: #78c9fc;
  --blue-lightest: #b9e3fe;
  --blue-subtle: #ebf7ff;
  --blue-subtlest: #f9fafb;
  --blue-shadow: #78c9fc;

  --green-main: #63ea47;
  --green-darker: #37ce18;
  --green-lighter: #a1f291;
  --green-subtle: #effded;
  --green-shadow: #a1f291;

  --red-main: #ec4c4c;
  --red-darker: #de1717;
  --red-lighter: #f28282;
  --red-subtle: #fef6f6;
  --red-shadow: #f28282;

  --orange-main: #ffaa2b;
  --orange-darker: #e58a00;
  --orange-lighter: #ffc46b;
  --orange-subtle: #fff7eb;

  --elevation-1: 0px 0.5px 2px 0px rgba(134, 154, 166, 0.1);
  --elevation-2: 0px 2px 4px 0px rgba(134, 154, 166, 0.1);
  --elevation-3: 0px 4px 8px 0px rgba(134, 154, 166, 0.1);
  --elevation-4: 0px 8px 16px 0px rgba(134, 154, 166, 0.1);
  --elevation-5: 0px 16px 24px 0px rgba(134, 154, 166, 0.1);
  --elevation-6: 0px 20px 32px 0px rgba(134, 154, 166, 0.1);
  --elevation-primary: 0px 2px 4px rgba(120, 201, 252, 0.16);
  --elevation-mobile: 0px 0px 3px rgba(40, 41, 61, 0.08);

  --nhg: neue-haas-grotesk-text, sans-serif;
  --nhg-display: neue-haas-grotesk-display, sans-serif;
}

body {
  margin: 0;
  font-family: var(--nhg), -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  color: var(--dark-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

a {
  text-decoration: none;
}

:root {
  --toastify-color-progress-dark: #13a0fa !important;
  --toastify-color-dark: #2e3438 !important;
}
