.enquiry-browser__wrapper {
  height: 90%;
}

.enquiry-browser {
  height: 100%;
  width: 480px;
  display: flex;
  flex-direction: column;
}

.enquiry-browser__nav {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  padding-left: 16px;
  padding-right: 8px;
  font-size: 10px;
}

.enquiry-browser__nav__title {
}

.enquiry-browser__nav__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.enquiry-browser__nav__options__option {
  margin-left: 12px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.enquiry-browser__nav__options__option__icon {
  stroke: var(--dark-2);
  height: 14px;
}

.enquiry-browser__nav__options__option.disabled {
  pointer-events: none;
}

.enquiry-browser__nav__options__option.disabled
  .enquiry-browser__nav__options__option__icon {
  stroke: var(--light-4);
}

.enquiry-browser__body {
  flex-grow: 1;
  overflow: auto;
}

.enquiry-browser__body__label {
  font-size: 8px;
  color: var(--dark-3);
  margin-bottom: 8px;
}

.enquiry-browser__body__from {
  padding: 16px;
}

.enquiry-browser__body__subject {
  padding: 16px;
}

.enquiry-browser__body__subject__text {
  font-weight: 600;
}

.enquiry-browser__body__desc {
  padding: 16px;
}

.enquiry-browser__footer {
  height: 28px;
  border-top: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.enquiry-browser__footer__read-label {
  font-size: 10px;
  margin-right: 8px;
}
