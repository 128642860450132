.location-search__menu {
  width: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: var(--elevation-3);
  margin-top: 8px;
}

.location-search__menu__loading {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-search__menu__item {
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.location-search__menu__item:hover {
  background: var(--light-2);
}
