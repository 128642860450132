.View {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.View .header {
  height: 56px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px whitesmoke;
  flex-shrink: 0;
  padding-left: 32px;
  padding-right: 32px;
  flex-shrink: 0;
}
.View .header .title {
  margin-right: auto;
  font-weight: 700;
  font-size: 16px;
}

.View .header .back-icon {
  margin-right: 16px;
  font-size: 10px;
}

.View .header .back-icon:hover {
  cursor: pointer;
}

.View .header .options {
}

.View .header .options .option {
  color: white;
  background: #60bb60;
  border-radius: 3px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  margin-left: 16px;
  font-weight: 600;
}

.View .header .options .option:hover {
  cursor: pointer;
}

.View .header .options .option.delete {
  background: red;
}

.View .tabs {
  height: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: solid 1px whitesmoke;
  flex-shrink: 0;
}

.View .tabs .tab {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  box-sizing: border-box;
  color: grey;
  margin-right: 16px;
}

.View .tabs .tab.active {
  color: var(--blue);
  font-weight: 600;
  border-bottom: solid 2px var(--blue);
}

.View .body {
  background: whitesmoke;
  flex-grow: 1;
  box-sizing: border-box;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  overflow: auto;
  /* padding-bottom: 16px; */
  /* padding-left: 32px; */
  /* padding-right: 32px; */
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; */
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center; */
}

.View .body .page {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
}

.View .body .Table {
}

.View .body .Table .Table-header {
  background: white;
}

.View .body .section {
  width: 50%;
  padding-left: 16px;
  padding-right: 16px;
}

.View .body .card {
  background: white;
  padding: 16px;
  border-radius: 4px;
  margin-top: 32px;
}

.View .body .card .card-title {
  border-bottom: solid 1px whitesmoke;
  padding-bottom: 16px;
  font-weight: 600;
}

.View .body .card .card-body {
  background: white;
  padding-top: 16px;
  padding-bottom: 16px;
}

.View .body .card .card-footer {
  border-top: solid 1px whitesmoke;
  padding-top: 16px;
}

.View .body .card .card-body .link-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.View .body .card .card-body .link-wrapper .copy-link {
  color: grey;
  margin-left: 8px;
}

.View .body .card .card-body .link-wrapper a {
  margin-right: auto;
}

.View .body .card .card-body img {
  width: 100%;
}

.View .body .card .card-body .card-body-group {
  border: solid 1px whitesmoke;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  position: relative;
  margin-bottom: 16px;
  padding: 16px;
}

.View .body .card .card-body .card-body-group .card-body-group-title {
  background: white;
  position: absolute;
  top: -8px;
  font-weight: 600;
  color: grey;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
}

.View .body .card .card-footer {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.View .body .card .card-footer .error {
  color: var(--red);
  margin-left: 16px;
  transition: all 0.2s ease-out;
}
