.sport-position-types {
  grid-column-start: 6;
  grid-column-end: 13;
  margin-top: 24px;
}

.sport-position-types__add-btn {
  color: var(--green-darker);
  cursor: pointer;
}

.sport-position-types__groups {
}

.sport-position-types__groups__group {
}

.sport-position-types__groups__group__title {
  height: 20px;
  background: var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  color: var(--dark-4);
  font-weight: 700;
}

.sport-position-types__groups__group__items {
}

.sport-position-types__groups__group__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px var(--light-2);
}

.sport-position-types__groups__group__items__item__text {
  /* padding-left: 12px; */
}

.sport-position-types__groups__group__items__item__text__primary {
  font-weight: 600;
}

.sport-position-types__groups__group__items__item__text__secondary {
  color: var(--dark-4);
}

.sport-position-types__groups__group__items__item__options {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sport-position-types__groups__group__items__item__options__option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 16px;
  border: none;
}

.sport-position-types__groups__group__items__item__options__option__icon {
  height: 14px;
}

.sport-position-types__groups__group__items__item__options__option.delete {
  background: var(--red-subtle);
}

.sport-position-types__groups__group__items__item__options__option.delete
  .sport-position-types__groups__group__items__item__options__option__icon {
  stroke: var(--red-darker);
}

.sport-position-types__groups__group__items__item__options__option.edit {
  background: var(--orange-subtle);
}

.sport-position-types__groups__group__items__item__options__option.edit
  .sport-position-types__groups__group__items__item__options__option__icon {
  fill: var(--orange-darker);
}

.sport-position-types__groups__group__items__item__options__option.disabled {
  opacity: 0.3;
  pointer-events: none;
}
