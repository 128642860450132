.modal-form {
  background: white;
  width: 30%;
  border-radius: 3px;
  padding: 16px;
  box-sizing: border-box;
  /* height: auto; */
}

.modal-form .title {
  font-weight: 800;
  margin-bottom: 16px;
}

.modal-form .title.with-secondary {
  margin-bottom: 8px;
}

.modal-form .secondary-text {
  margin-bottom: 16px;
}

.modal-form .form-group {
  margin-bottom: 16px;
}

.modal-form .form-group .label {
  font-weight: 300;
  margin-bottom: 4px;
}

.modal-form .form-group input[type="text"] {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 32px;
  padding-left: 4px;
}

.modal-form .form-group input[type="number"] {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 32px;
  padding-left: 4px;
}

.modal-form .form-group input[type="date"] {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 32px;
  padding-left: 4px;
}

.modal-form .form-group select {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 32px;
  padding-left: 4px;
  /* padding-right: 4px; */
}

.modal-form .options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-form .options .option {
  height: 32px;
  background: black;
  color: white;
  font-weight: 600;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 3px;
  margin-left: 16px;
}

.modal-form .options .option:hover {
  cursor: pointer;
}

.modal-form .options .option.submit {
  background: #60bb60;
}

.modal-form .options .option.outline {
  background: white;
  color: var(--blue);
  border: solid 1px var(--blue);
}

.modal-card {
  background: white;
  width: 30%;
  border-radius: 3px;
  padding: 16px;
  box-sizing: border-box;
}

.modal-card .modal-card-header {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px whitesmoke;
}

.modal-card .modal-card-header .primary {
  font-size: 16px;
  font-weight: 600;
}

.modal-card .modal-card-header .secondary {
  color: grey;
}

.modal-card .modal-card-body {
}

.modal-card .modal-card-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px whitesmoke;
}
