.fixture-lineup-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fixture-lineup-container .formation {
  flex-grow: 1;
}

.fixture-lineup-container .list {
  width: 35%;
  /* border-left: solid 1px whitesmoke; */
}

.fixture-lineup-container .list .list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 32px;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px whitesmoke;
}

.fixture-lineup-container .list .list-item:last-child {
  border-bottom: none;
}

.fixture-lineup-container .list .list-item .formation-position {
  width: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;

  color: grey;
}

.fixture-lineup-container .list .list-item .formation-position.selectable {
  color: var(--green);
  font-weight: 600;
}

.fixture-lineup-container .list .list-item .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-grow: 1;
}

.fixture-lineup-container .list .list-item .meta .jersey-num {
  color: grey;
  width: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.fixture-lineup-container .list .list-item .meta .player-name {
  margin-left: 4px;
}

.fixture-lineup-container .list .list-item .options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fixture-lineup-container .list .list-item .options .option {
}

.fixture-lineup-container .list .list-item .options .option.delete {
  color: var(--red);
}

.fixture-lineup-container
  .list
  .list-item.add-lineup-player
  .meta
  .player-name {
  color: var(--green);
}

.fixture-lineup-container
  .list
  .list-item.add-lineup-player
  .meta
  .player-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.add-lineup-player {
  margin-left: auto;
}

.upload-lineup-players {
  margin-left: auto;
  margin-top: 8px;
}
