.fixture-generator-preview {
}

.fixture-generator-preview .event-part {
}

.fixture-generator-preview .event-part .title {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 40px;
  border-top: solid 1px whitesmoke;
  border-bottom: solid 1px whitesmoke;
  -ms-align-items: center;
  align-items: center;
}

.fixture-generator-preview .event-part .title .label {
  margin-left: auto;
  margin-right: auto;
}

.fixture-generator-preview .event-part .title .btn {
  height: 40px;
  width: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.fixture-generator-preview .event-part .fixture-item {
  margin-bottom: 16px;
}
/* /////////////////////////////////////////////////// */
.fixture-generator-preview .event-parts-container {
  overflow: hidden;
}

.fixture-generator-preview .event-parts-container .event-parts-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fixture-generator-preview .event-parts-container .event-part {
  width: 100%;
  flex-shrink: 0;
}
