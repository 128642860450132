.youtube-video {
  overflow: hidden;
  position: relative;
  /* height: 0; */
  height: 100%;
  width: 100%;
}

.youtube-video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
