.App-search {
  height: 28px;

  border-radius: 4px;
  overflow: hidden;
  background: white;
  position: relative;
  padding-right: 28px;
  border: solid 1px var(--light-3);
}

.App-search input {
  height: 100%;
  width: 100%;
  padding: 0;
  padding-left: 12px;
  border: none;
  outline: none;

  font-family: var(--nhg);
  font-size: 10px;
}

.App-search__dismiss {
  position: absolute;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  cursor: pointer;
}

.App-search__dismiss__icon {
  height: 14px;
  stroke: var(--red-lighter);
}
