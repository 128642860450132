.note {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 16px;
}

.note .meta {
  flex-grow: 1;
}

.note .meta .createdAt {
  color: grey;
}

.note .option {
  color: var(--red);
}
