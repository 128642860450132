.search-with-dropdown__menu {
  width: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: var(--elevation-3);
  margin-top: 8px;
}

.search-with-dropdown__menu__loading {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-with-dropdown__menu__item {
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  min-height: 24px;
}

.search-with-dropdown__menu__item__img {
  height: 28px;
  width: 28px;
  background: var(--blue-subtle);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.search-with-dropdown__menu__item__img.no-bg {
  background: none;
}

.search-with-dropdown__menu__item__text {
  padding-left: 8px;
  font-size: 10px;
}

.search-with-dropdown__menu__item__text__primary {
  font-weight: 700;
}

.search-with-dropdown__menu__item__text__secondary {
  color: var(--dark-3);
}

.search-with-dropdown__menu__item:hover {
  background: var(--light-2);
}
