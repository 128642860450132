.App-toggle {
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.App-toggle__toggle {
  height: 16px;
  width: 28px;
  box-sizing: border-box;
  background: var(--light-3);
  border: solid 1px var(--light-4);
  border-radius: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;

  transition: all 0.2s ease-out;
}

.App-toggle__toggle.selected {
  background: var(--blue-main);
  border-color: var(--blue-main);
  justify-content: flex-end;
}

.App-toggle__toggle__handle {
  background: white;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  box-shadow: var(--elevation-1);
}

.App-toggle__label {
  font-size: 8px;
  /* margin-right: 8px; */
  color: var(--dark-4);
  font-weight: 400;
  margin-bottom: 4px;
  letter-spacing: 0.4px;
  /* font-family: var(--nhg-display); */
}
