.fixture-list {
}

.fixture-list .fixture-list-header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  font-weight: 600;
  /* height: 54px; */
  padding-bottom: 16px;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px whitesmoke;
}

.fixture-list .fixture-list-header .btn {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.fixture-list .fixture-list-header .title {
  margin-left: auto;
  margin-right: auto;
}

.fixture-list .fixture-list-body {
}

.fixture-list .fixture-list-body .fixture-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 40px;
  -ms-align-items: center;
  align-items: center;
  margin-top: 8px;
}

.fixture-list .fixture-list-body .fixture-item .teams {
  margin-right: auto;
}

.fixture-list .fixture-list-body .fixture-item .datetime {
  font-size: 10px;
  /* text-decoration: underline; */
  color: grey;
  border-right: solid 1px whitesmoke;
  padding-right: 16px;
}

.fixture-list .fixture-list-body .fixture-item .datetime .date:hover {
  text-decoration: underline;
}

.fixture-list .fixture-list-body .fixture-item .datetime .time:hover {
  text-decoration: underline;
}

.fixture-list .fixture-list-body .fixture-item .options {
}

.fixture-list .fixture-list-body .fixture-item .options .option {
  height: 24px;
  width: 24px;
  /* background: whitesmoke; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 3px;
  font-size: 12px;
  transition: all 0.2s ease-out;
}

.fixture-list .fixture-list-body .fixture-item .options .option.edit {
  background: var(--yellow-subtle);
  color: var(--yellow);
}

.fixture-list .fixture-list-body .fixture-item .options .option.edit:hover {
  background: var(--yellow);
  color: white;
}

.generator-helper {
  padding-top: 16px;
}

.generator-helper .helper-text {
  margin-bottom: 16px;
}

.generator-helper .helper-options {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.generator-helper .helper-options .helper-item-wrapper {
  width: 33.33%;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.generator-helper .helper-options .helper-item-wrapper.even {
  /* padding-right: 8px; */
}

.generator-helper .helper-options .helper-item-wrapper.odd {
  /* padding-left: 8px; */
}

.generator-helper .helper-options .helper-item-wrapper:first-child {
  /* padding-right: 16px; */
  padding-left: 0;
}

.generator-helper .helper-options .helper-item-wrapper:last-child {
  /* padding-left: 16px; */
  padding-right: 0;
}

.generator-helper .helper-options .helper-item-wrapper .helper-item {
  background: white;
  box-sizing: border-box;
  padding: 16px;
  border: solid 1px var(--blue);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.generator-helper
  .helper-options
  .helper-item-wrapper
  .helper-item
  .primary-text {
  margin-bottom: 8px;
  font-weight: 600;
}

.generator-helper
  .helper-options
  .helper-item-wrapper
  .helper-item
  .secondary-text {
}

.generator-helper .helper-options .helper-item-wrapper .helper-item:hover {
  background: var(--blue);
  color: white;
}
