.Delete-reg {
  padding: 16px;
  border-top: solid 1px var(--light-2);
}

.Delete-reg__btn {
  background: var(--red-subtle);
  color: var(--red-darker);
  width: 100%;
}

.Delete-reg__form {
}

.Delete-reg__form__statement {
}

.Delete-reg__form__options {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.Delete-reg__form__options__option {
  margin-right: 16px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Delete-reg__form__options__option.danger {
  background: var(--red-main);
  color: var(--light-2);
}

.Delete-reg__form__options__option.loading {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}
