.Table {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Table .Table-header {
  height: 56px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: solid 1px whitesmoke;
  flex-shrink: 0;
  padding-left: 32px;
  padding-right: 32px;
  -ms-align-items: center;
  align-items: center;
}

.Table .Table-header .title {
  margin-right: auto;
  font-weight: 700;
  font-size: 16px;
}

.Table .Table-header .options {
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Table .Table-header .options .search {
  position: relative;
}

.Table .Table-header .options .search input {
  height: 24px;
  padding: 0;
  box-sizing: border-box;
  /* border: none; */
  border: solid 1px whitesmoke;
  padding-left: 8px;
  font-size: 12px;
  background: whitesmoke;
  /* pointer-events: none; */
}

.Table .Table-header .options .search .dismiss-search-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Table .Table-header .options .option {
  color: white;
  background: #60bb60;
  border-radius: 3px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  margin-left: 16px;
  font-weight: 600;
}

.Table .Table-header .options .option.outline {
  border: solid 1px #37a1fa;
  color: #37a1fa;
  background: white;
}

.Table .Table-header .options .option:hover {
  cursor: pointer;
}

.Table .Table-fields {
  height: 20px;
  background: whitesmoke;
  flex-shrink: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 300;
  border-bottom: solid 1px whitesmoke;
  /* padding-left: 16px; */
  padding-left: 16px;
  padding-right: 16px;
}

.Table .Table-fields .field {
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}

.Table .Table-fields .field:last-child {
  text-align: right;
}

.Table .Table-fields .field:first-child {
  text-align: left;
}

.Table .Table-rows {
  flex-grow: 1;
  overflow: auto;
  background: whitesmoke;
}

.Table .Table-rows .tr {
  height: 40px;
  border-bottom: solid 1px whitesmoke;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  transition: all 0.2s ease-out;
}

.Table .Table-rows .tr.even {
  background: white;
}

.Table .Table-rows .tr.odd {
  background: rgba(255, 255, 255, 0.8);
}

.Table .Table-rows .tr:hover {
  cursor: pointer;
  background: var(--blue);
  color: white;
}

.Table .Table-rows .tr .td {
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}

.Table .Table-rows .tr .td:last-child {
  text-align: right;
}

.Table .Table-rows .tr .td:first-child {
  text-align: left;
}
