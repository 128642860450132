.upload-event-stats-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-event-stats-page__container {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px;
}

.upload-event-stats-page__container__icon {
}

.upload-event-stats-page__container__title {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-2);
  margin-bottom: 16px;
  margin-bottom: 16px;
}

.upload-event-stats-page__container__btn {
  background: var(--blue-darker);
  color: var(--light-2);
}
