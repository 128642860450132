.event-info-page__details {
  grid-column-start: 1;
  grid-column-end: 6;
  margin-top: 24px;
}

.event-info-page__details__items {
  padding-top: 8px;
  padding-bottom: 8px;
}
