.app-avatar {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;

  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.app-avatar::before {
  content: "";
  position: absolute;
  inset: 0;
  animation: pulse 2.5s infinite;
  background-color: rgba(255, 255, 255, 0.1);
}

.app-avatar img {
  width: 100%;
  /* aspect-ratio: 1 / 1; */
  display: block;
  object-position: center;
  object-fit: cover;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.app-avatar.loaded {
  filter: none;
  -webkit-filter: none;
}

.app-avatar.loaded img {
  opacity: 1;
}

.app-avatar.loaded::before {
  content: none;
  animation: none;
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }

  50% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
