.Auth {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  background: whitesmoke;
}

.Auth .Login {
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
  padding: 32px;
  background: white;
  padding-top: 16px;
  margin-top: 64px;
  width: 240px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Auth .Login .title {
  margin-bottom: 16px;
  text-align: center;
  font-weight: 800;
}

.Auth .Login .form-group {
  margin-bottom: 16px;
}

.Auth .Login .form-group input {
  height: 32px;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  padding-left: 8px;
}

.Auth .Login button {
  height: 32px;
  color: white;
  font-weight: 600;
  margin-top: 8px;
  border: none;
  background: #60bb60;
  border-radius: 8px;
}
