.App-modal-form__instructions {
  padding-top: 12px;
  background: var(--red-subtle);
  margin: 16px;
  border-radius: 8px;
  padding-bottom: 8px;
}

.App-modal-form__instructions__title {
  padding-left: 16px;
  font-weight: 700;
  color: var(--red-darker);
}

.App-modal-form__instructions__list {
  padding-left: 32px;
  padding-right: 16px;
}

.App-modal-form__instructions__list li {
  margin-bottom: 8px;
  font-size: 10px;
}

.App-modal-form__instructions__list li .bold {
  font-weight: 700;
}
