.report-browser__wrapper {
  width: 80%;
  height: 90%;
}

.report-browser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.report-browser__content {
  width: 60%;
  height: 100%;
  flex-shrink: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 32px;
  padding-bottom: 0;
}

.report-browser__content .app-post-card {
  width: 100%;
  padding-bottom: 16px;
}

.report-browser__content img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}

.report-browser__meta {
  width: 40%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.report-browser__meta__header {
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  padding-left: 16px;
  padding-right: 8px;
  font-size: 10px;
  flex-shrink: 0;
}

.report-browser__meta__header__title {
}

.report-browser__meta__header__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.report-browser__meta__header__options__option {
  margin-left: 12px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.report-browser__meta__header__options__option__icon {
  stroke: var(--dark-2);
  height: 14px;
}

.report-browser__meta__header__options__option.disabled {
  pointer-events: none;
}

.report-browser__meta__header__options__option.disabled
  .report-browser__meta__header__options__option__icon {
  stroke: var(--light-4);
}

.report-browser__meta__tickets {
  flex-grow: 1;
  overflow: auto;
}

.report-browser__meta__tickets__ticket {
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.report-browser__meta__tickets__ticket__user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.report-browser__meta__tickets__ticket__user__img {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: var(--blue-subtle);
}

.report-browser__meta__tickets__ticket__user__name {
  font-weight: 600;
  margin-left: 8px;
}

.report-browser__meta__tickets__ticket__description {
  padding-left: 28px;
  line-height: 1.5em;
  color: var(--dark-3);
  /* padding-top: 4px; */
}

.report-browser__meta__tickets__ticket__description .placeholder {
  color: var(--light-3);
}

.report-browser__meta__actions {
  flex-shrink: 0;
  padding: 16px;
  border-top: solid 1px var(--light-2);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.report-browser__meta__actions__action {
  height: 40px;
}

.report-browser__meta__actions__action.danger {
  background: var(--red-main);
  color: var(--light-2);
}
