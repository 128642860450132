.stat-item {
  height: 32px;
  border-bottom: solid 1px whitesmoke;
}

.stat-item .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  height: 32px;
}

.stat-item .meta .timestamp {
  width: 32px;
}

.stat-item .meta .description {
}

.stat-item .meta .options {
  margin-left: auto;
}

.stat-item .meta .options .option.delete {
  color: var(--red);
}
