.AcceptInvite {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  background: whitesmoke;
  padding-top: 64px;
  box-sizing: border-box;
}

.AcceptInvite .form {
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
  padding: 32px;
  background: white;
  padding-top: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.AcceptInvite .form .title {
  font-weight: 600;
  margin-bottom: 16px;
}

.AcceptInvite .form input {
  height: 24px;
  padding: 0;
  box-sizing: ;
  padding-left: 8px;
}

.AcceptInvite .form button {
  height: 32px;
  color: white;
  font-weight: 600;
  margin-top: 16px;
  border: none;
  background: #60bb60;
}
