.Editable {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.Editable__content {
  flex-grow: 1;
}

.Editable__content__label {
  /* font-family: var(--nhg-display); */
  color: var(--dark-4);
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 400;
  /* font-family: var(--nhg-display); */
}

.Editable__content__value {
  font-weight: 600;
  color: var(--dark-1);
}

.Editable__options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Editable__options__option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 8px;
  border: none;
}

.Editable__options__option__icon {
  height: 14px;
}

.Editable__options__option.edit {
  background: var(--orange-subtle);
}

.Editable__options__option.edit .Editable__options__option__icon {
  fill: var(--orange-darker);
}

.Editable__options__option.success {
  background: var(--green-subtle);
}

.Editable__options__option.success .Editable__options__option__icon {
  fill: var(--green-darker);
}

.Editable__options__option.neutral {
  background: var(--light-2);
}

.Editable__options__option.neutral .Editable__options__option__icon {
  stroke: var(--light-4);
}

/* FORM */

.Editable__content__input-wrapper {
  height: 32px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
  /* padding-right: 8px; */
  position: relative;
  /* overflow: hidden; */
}

.Editable__content__input-wrapper:focus-within {
  border-color: var(--blue-main);
}

.Editable__content__input-wrapper__label {
  position: absolute;
  top: -6px;
  left: 4px;
  background: white;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 8px;
  z-index: 2;
}

.Editable__content__input-wrapper__input {
  height: 30px;
  width: 100%;
  border: none;
  box-sizing: border-box !important;
  outline: none;
  padding-left: 8px;
  font-family: var(--nhg);
  border-radius: 4px;
  font-size: 12px;
}

.Editable__options__loader {
  width: 56px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
