.Home {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  box-sizing: border-box;
  padding-top: 64px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  background: whitesmoke;
}

.Home .links {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* border: solid 1px grey; */
  border-radius: 4px;
  padding: 16px;
  position: relative;
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
  width: 200px;
  background: white;
}

.Home .links .title {
  font-size: 10px;
  font-weight: 600;
  color: #555770;
}

.Home .links .link {
  text-decoration: none;
  margin-top: 16px;
  color: black;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.Home .links .link .label {
  margin-right: auto;
}

.Home .links .link:hover .label {
  text-decoration: underline;
}
