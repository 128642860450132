/* INDEX PAGE STYLES */
.App-index-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.App-index-page__header {
  /* padding: 16px; */
  height: 60px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  background: #f6f8f9;
  background: var(--light-2);
  flex-shrink: 0;
  /* box-shadow: var(--elevation-2); */
}

.App-index-page__header__title {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* color: var(--light-4); */
}

.App-index-page__header__options {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-index-page__header__options__add {
  background: var(--green-darker) !important;
  color: var(--light-1) !important;
}

.App-index-page__header__options__search {
  margin-right: 24px;
  width: 240px;
}

.App-index-page__list {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
}

.App-index-page__list__loading {
  padding: 40px;
}

.App-index-page__list__item {
  background: white;
  /* box-shadow: var(--elevation-1); */
  /* border-radius: 8px; */
  padding: 12px;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border-bottom: solid 1px var(--light-2);
  /* height: 40px; */
  flex-shrink: 0;
  /* margin-bottom: 16px; */
}

.App-index-page__list__item__img {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  overflow: hidden;
  background: var(--blue-subtlest);
}

.App-index-page__list__item__img.no-bg {
  background: none;
}

.App-index-page__list__item__text {
  padding-left: 12px;
  /* font-family: var(--nhg-display); */
}

.App-index-page__list__item__text__primary {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2px;
}

.App-index-page__list__item__text__secondary {
  font-size: 10px;
  color: var(--dark-3);
}

.App-index-page__list__item:hover {
  background: var(--light-2);
}

/* MODAL FORM STYLES */
.App-modal-form {
  background: white;
  width: 400px;
  max-height: 80%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.App-modal-form__header {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  border-bottom: solid 1px var(--light-2);
}

.App-modal-form__header__title {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
}

.App-modal-form__header__title__secondary {
  font-size: 10px;
  font-weight: 400;
  color: var(--dark-4);
  font-family: var(--nhg);
}

.App-modal-form__header__dismiss {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
}

.App-modal-form__header__dismiss__icon {
  stroke: var(--dark-3);
  height: 14px;
}

.App-modal-form__body {
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
}

.App-modal-form__body__group {
  margin-bottom: 16px;
}

.App-modal-form__body__group__label {
  /* font-family: var(--nhg-display); */
  font-size: 10px;
  margin-bottom: 8px;
}

.App-modal-form__body__group__label .required {
  color: var(--red-main);
}

.App-modal-form__body__group__input {
  height: 32px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
  padding-right: 8px;
  /* overflow: hidden; */
}

.App-modal-form__body__group__input:focus-within {
  border-color: var(--blue-lighter);
}

.App-modal-form__body__group__input__selected-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  /* padding-right: 8px; */
  cursor: pointer;
}

.App-modal-form__body__group__input__selected-item__label {
  font-size: 12px;
}

.App-modal-form__body__group__input__selected-item__dismiss {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.App-modal-form__body__group__input__selected-item__dismiss__icon {
  height: 14px;
  stroke: var(--red-lighter);
}

.App-modal-form__body__group__input__text {
  height: 100%;
  width: 100%;
  padding: 0;
  padding-left: 8px;
  border: none;
  outline: none;
  font-family: var(--nhg);
  font-size: 12px;
  background: none;
}

.App-modal-form__body__group__input__select {
  height: 100%;
  width: 100%;
  padding: 0;
  padding-left: 8px;
  border: none;
  outline: none;
  font-family: var(--nhg);
  font-size: 12px;
  background: none;
}

.App-modal-form__body__error {
  background: var(--red-subtle);
  border-radius: 4px;
  padding: 8px;
  color: var(--red-darker);
  font-weight: 700;
  font-size: 10px;
}

.App-modal-form__body__submit-btn {
  background: var(--green-darker);
  color: var(--light-2) !important;
  margin-top: 16px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-modal-form__body__submit-btn:hover {
  background: var(--green-main);
}

.App-modal-form__body__submit-btn.loading {
  background: var(--green-lighter) !important;
}

.App-modal-form__body__delete-btn {
  background: var(--red-darker);
  color: var(--light-2) !important;
  margin-top: 16px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-modal-form__body__delete-btn:hover {
  background: var(--red-main);
}

.App-modal-form__body__delete-btn.loading {
  background: var(--red-lighter) !important;
}

/* VIEW PAGE STYLES */
.App-view-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.App-view-page__header {
  height: 60px;
  padding-left: 20px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-bottom: solid 1px var(--light-3); */
  background: #f6f8f9;
  background: var(--light-2);
  flex-shrink: 0;
}

.App-view-page__header__back-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.App-view-page__header__back-btn__icon {
  height: 14px;
  stroke: var(--dark-4);
}

.App-view-page__header__title {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding-left: 16px;
}

.App-view-page__header__options {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-view-page__header__options__delete {
  background: var(--red-subtle) !important;
  color: var(--red-darker) !important;
  border: solid 1px var(--red-darker) !important;
}

.App-view-page__tabs {
  background: var(--light-2);
  border-bottom: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.App-view-page__tabs__item {
  height: 40px;
  margin-right: 16px;
  padding-right: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--nhg-display);
}

.App-view-page__tabs__item.selected {
  border-bottom: solid 2px var(--blue-main);
}

.App-view-page__body {
  flex-grow: 1;
  overflow: auto;
}

/* COMMON */

.App-loading-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
